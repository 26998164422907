// import React from 'react';
// import * as PropTypes from 'prop-types';
// import styles from './flyer-fullsize.module.scss';
// import Img from 'gatsby-image';
// import SiteLink from '../site-link';
// import {useImageStaticQuery} from "../../../hooks/useImagesStaticQuery";
//
// const FlyerFullsize = ({ content }) => {
//   let image = useImageStaticQuery(content.image).childImageSharp;
//   console.log(content)
//   console.log(content.tag)
//
//   return (
//     <div className={styles.flyerFullsizeSection}>
//       <div className={styles.flyerFullsizeContainer}>
//         <div className={styles.flyerFullsizeColumn}>


//           {content.description && (
//             <p className={styles.flyerFullsizeSummary}>{content.description}</p>
//           )}
//           {content.link && content.link.uri && content.link.title && (
//             <SiteLink className={styles.flyerFullsizeLink} href={content.link.uri}>
//               {content.link.title}
//             </SiteLink>
//           )}
//         </div>
//         <div className={styles.flyerFullsizeColumn}>
//           {content.image && content.image.fluid && content.alt && (
//             <div className={styles.flyerFullsizeImage}>
//               <Img fluid={image.fluid} alt={content.alt} />
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default FlyerFullsize;

import React, {useContext} from 'react';
import { has, get } from 'lodash';
import * as PropTypes from 'prop-types';
import styles from './flyer-fullsize.module.scss';
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../../hooks/useImagesStaticQuery';
import {AppContext} from "../../layout/AppLayout";

const FlyerFullsize = ({ content }) => {
  // Context
  const app = useContext(AppContext);
  // Assets
  const imageData = get(useImageStaticQuery(content.image), content.cmsImage);
  const image = has(content, 'image.fluid') ? content.image.fluid : imageData;
  

  return (
    <div className={styles.flyerFullsizeSection} data-app={app}>
      <div className={styles.flyerFullsizeContainer}>
        <div className={styles.flyerFullsizeColumn}>
          {content.label && <h3 className={styles.flyerFullsizeSubtitle}>{content.label}</h3>}
          {content.title && <h2 className={styles.flyerFullsizeTitle}>{content.title}</h2>}
          {content.text && (
            <p className={styles.flyerFullsizeSummary}>{content.text}</p>
          )}
          {content.link && content.link.uri && (
   <a className={styles.flyerFullsizeLink} href={content.link.uri.replace('internal:', '')} target={app ? `_blank` : ''}>
              {content.link.title}
            </a>
          )}
        </div>
        <div className={styles.flyerFullsizeColumn}>
          <div className={styles.flyerFullsizeImage}>
            {!!image && <Img fluid={image} alt={content.alt || ""} />}
          </div>
        </div>
      </div>
    </div>
  );
};

FlyerFullsize.propTypes = {
  content: PropTypes.object.isRequired,
  fluid: PropTypes.object,
};

export default FlyerFullsize;
